<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
}
</script>
<style lang="less">
@import './assets/common.less';
* {
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
ul,
ol {
  list-style: none;
}
html {
  font-size: calc(100vw / 20);
}
@media screen and (min-width: 500px){
  html {
      font-size: 20px;
  }
}
html,body{
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #404040;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  background: #f0f0f0;
}
</style>
